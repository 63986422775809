/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import DBM from "../img/DBM.svg";
import DV from "../img/DV.svg";
import ML from "../img/ML.svg";
import NLP from "../img/NLP.svg";
import ANA from "../img/ANA.svg";


import { Fade } from "react-reveal";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const expertise = [
  {
    name: 'Transformer Models',
    icon: NLP,
  },
  {
    name: 'Backend Development',
    icon: DBM,
  },
  {
    name: 'MLOps & Deployment',
    icon: ML,
  },
  {
    name: 'Vector Databases',
    icon: DV,
  },
  {
    name: 'Recommendation Systems',
    icon: ANA,
  },
]
export default function AboutMe() {
  return (
    <div className="relative h-max font-sans bg-gradient-to-b from-white to-gray-50/30" id="aboutme">
      <main className="lg:relative h-full max-w-7xl mx-auto">
        <div className="mx-auto w-full pb-20 pt-16">
          {/* Header */}
          <Fade left>
            <div className="flex items-center bg-blue-100 w-max h-7 px-3 rounded-lg mb-5">
              <p className="font-normal text-sm text-blue-700">ABOUT ME</p>
            </div>
          </Fade>

          {/* Main Content */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-20">
            {/* Left Column - Main Text */}
            <div className="space-y-8">
              <Fade left>
                <div className="relative">
                  <h1 className="text-4xl font-bold tracking-tight sm:text-5xl mb-8">
                    <span className="inline-block bg-gradient-to-r from-gray-900 via-gray-800 to-blue-700 bg-clip-text text-transparent">
                      Hi, I'm Nicolai Herforth
                    </span>
                  </h1>
                  <div className="prose prose-lg text-gray-600 max-w-none space-y-6">
                    <p className="relative pl-4 border-l-2 border-blue-500">
                      A technical profile who builds systems that make complicated tasks simpler. With a background in machine learning, software engineering, and data integration, I create scalable solutions that help teams work more efficiently.
                    </p>
                    <p>
                      I thrive where technology meets real-world problems. I’ve designed multi-agent frameworks, tackled resource planning, researched model architectures, and used AI to streamline operations.
                    </p>
                    <div className="bg-blue-50 p-6 rounded-xl">
                      <blockquote className="text-blue-600 font-medium italic">
                        "See the bigger picture, without changing the frame."
                      </blockquote>
                      <p className="mt-4 text-gray-600">
                        That’s how I approach problem-solving: find new solutions without breaking what already works.
                      </p>
                    </div>
                    <p>
                      Outside of work, I’m a tech geek who loves gaming and side projects. Whether I’m tinkering with code, upgrading my rig, or just nerding out about the latest hardware, I’m in my element.
                    </p>
                  </div>
                </div>
              </Fade>
            </div>

            {/* Right Column - Expertise Areas */}
            <div className="hidden sm:block space-y-8">
              <Fade right>
                <h2 className="text-2xl font-semibold text-gray-900 mb-6">Core Areas of Expertise</h2>
                <div className="grid gap-6">
                  {expertise.map((item, index) => (
                    <div key={item.name} 
                         className="flex items-center p-4 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300">
                      <div className="flex-shrink-0 p-3 bg-blue-50 rounded-lg">
                        <img src={item.icon} className="h-8 w-8" alt={item.name} />
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-medium text-gray-900">{item.name}</h3>
                        <p className="text-sm text-gray-500">{getExpertiseDescription(index)}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

function getExpertiseDescription(index) {
  const descriptions = [
    "Building and deploying state-of-the-art language models",
    "Developing robust and scalable server architectures",
    "Streamlining ML deployment and monitoring workflows",
    "Implementing efficient vector search solutions",
    "Creating personalized recommendation engines"
  ];
  return descriptions[index];
}
