import React, { useState } from 'react';
import { Fade } from "react-reveal";
import videoStill from "../img/video-still.jpg";
import compendLogo from "../img/compend-standalone.svg";
import drpPlatform from "../img/extance.jpg";

const ImageModal = ({ isOpen, onClose, imageSrc, altText }) => {
  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      
      const handleEscape = (e) => {
        if (e.key === 'Escape') {
          onClose();
        }
      };
      
      document.addEventListener('keydown', handleEscape);
      
      return () => {
        document.body.style.overflow = 'unset';
        document.removeEventListener('keydown', handleEscape);
      };
    }
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4"
      onClick={onClose}
      style={{ 
        backdropFilter: 'blur(5px)',
        position: 'fixed',
        zIndex: 999999999,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }}
    >
      <div 
        className="relative max-w-7xl max-h-[90vh] w-full animate-fadeIn"
        style={{ position: 'relative', zIndex: 999999999 }}
      >
        <button
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          className="absolute -top-10 right-0 text-white hover:text-gray-300 text-xl focus:outline-none"
          style={{ zIndex: 999999999 }}
          aria-label="Close modal"
        >
          ✕
        </button>
        <div 
          className="w-full h-full"
          onClick={(e) => e.stopPropagation()}
        >
          <img
            src={imageSrc}
            alt={altText}
            className="w-full h-full object-contain rounded-lg shadow-2xl"
            style={{ position: 'relative', zIndex: 999999999 }}
          />
        </div>
      </div>
    </div>
  );
};

const Cases = () => {
  const [modalImage, setModalImage] = useState(null);

  const cases = [
    {
      id: 1,
      title: "From Ideation to Engaging Videos",
      tagline: "Rethinking Content Creation with AI",
      description: "An AI platform that rethinks video production. You drag and drop your data, and the system handles the rest—writing the script, choosing media, adding effects and subtitles, and even recording a professional voiceover.",
      features: [
        "AI-powered script generation from raw data",
        "Automated media asset sourcing and curation",
        "Dynamic video composition and effects",
        "Automated subtitle generation and voiceover synthesis",
        "Drag-and-drop interface for data integration"
      ],
      techStack: ["PGVector", "Transformers", "Computer Vision", "Langchain"],
      image: videoStill,
      reverse: false,
      gradient: "from-blue-500 to-purple-500"
    },
    {
      id: 2,
      title: "Enterprise Dynamic Resource Planning AI Platform",
      tagline: "Intelligent Resource Planning & Analytics",
      description: "An AI platform that reworked four years of legacy documentation into a resource planning tool. It now delivers historical insights, streamlines tax reporting, and forecasts labor needs.",
      features: [
        "Centralized AI agent architecture",
        "Historical document reconciliation",
        "Intelligent tax reporting verification",
        "COGS calculation automation",
        "Predictive warehouse labor management"
      ],
      techStack: ["Effect.ts", "Supabase", "Letta AI", "Vercel", "Convex"],
      image: drpPlatform,
      reverse: true,
      gradient: "from-emerald-500 to-blue-500"
    }
  ];

  return (
    <section id="cases" className="relative py-20 bg-gradient-to-b from-gray-50 to-white rounded-lg">
      <ImageModal
        isOpen={!!modalImage}
        onClose={() => setModalImage(null)}
        imageSrc={modalImage}
        altText="Case study preview"
      />

      <div className="relative z-0">
        <div className="container mx-auto px-4">
          <Fade bottom>
            <div className="text-center mb-16">
              <span className="text-blue-600 font-semibold tracking-wider uppercase text-sm">Featured Work</span>
              <h2 className="text-4xl font-bold mt-2 mb-4">Innovative Solutions</h2>
              <p className="text-gray-600 max-w-2xl mx-auto">
                Leveraging cutting-edge AI technology to solve complex business challenges
              </p>
            </div>
          </Fade>
          
          {cases.map((caseItem) => (
            <Fade bottom key={caseItem.id}>
              <div className="mb-20 relative">
                <div className={`absolute inset-0 bg-gradient-to-r ${caseItem.gradient} opacity-5 rounded-3xl transform -skew-y-3`} />
                
                <div className={`relative flex flex-col ${caseItem.reverse ? 'lg:flex-row-reverse' : 'lg:flex-row'} items-center bg-white rounded-2xl shadow-xl overflow-hidden`}>
                  <div className="lg:w-1/2 p-6 hidden lg:block">
                    {caseItem.id === 1 ? (
                      <div className="space-y-16">
                        <div className="flex justify-center items-center">
                          <img 
                            src={compendLogo} 
                            alt="Compend Logo"
                            className="h-8 sm:h-10 md:h-12 w-auto brightness-0"
                          />
                        </div>
                        
                        <div className="relative group">
                          <div className="max-w-sm mx-auto relative">
                            <img 
                              src={videoStill} 
                              alt={caseItem.title}
                              className="rounded-xl shadow-2xl transform transition-transform duration-500 group-hover:scale-105 w-full cursor-pointer"
                              onClick={() => setModalImage(videoStill)}
                            />
                            <div 
                              className="absolute inset-0 bg-gradient-to-r from-blue-600/20 to-purple-600/20 rounded-xl 
                              opacity-0 transition-opacity duration-500 pointer-events-none"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="relative group">
                        <img 
                          src={caseItem.image} 
                          alt={caseItem.title}
                          className="rounded-xl shadow-2xl transform transition-transform duration-500 group-hover:scale-105 cursor-pointer"
                          onClick={() => setModalImage(caseItem.image)}
                        />
                        <div className="absolute inset-0 bg-gradient-to-r from-blue-600/20 to-purple-600/20 rounded-xl opacity-0 transition-opacity duration-500" />
                      </div>
                    )}
                  </div>

                  <div className="lg:w-1/2 p-8 lg:p-12">
                    <span className="text-blue-600 font-semibold tracking-wider uppercase text-sm">
                      {caseItem.tagline}
                    </span>
                    <h3 className="text-3xl font-bold mt-2 mb-4">{caseItem.title}</h3>
                    <p className="text-gray-600 mb-8 leading-relaxed">
                      {caseItem.description}
                    </p>

                    <div className="grid grid-cols-1 gap-3 mb-8">
                      {caseItem.features.map((feature, index) => (
                        <div 
                          key={index} 
                          className="group px-4 py-3 rounded-lg bg-gray-50/50 border-l-2 border-blue-400 hover:border-blue-600 hover:bg-gray-50 transition-all duration-300"
                        >
                          <span className="text-gray-700 group-hover:text-gray-900 transition-colors duration-300">
                            {feature}
                          </span>
                        </div>
                      ))}
                    </div>

                    <div className="flex flex-wrap gap-2">
                      {caseItem.techStack.map((tech, index) => (
                        <span key={index} className="px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm font-medium">
                          {tech}
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className="lg:hidden w-full p-6">
                    {caseItem.id === 1 ? (
                      <div className="space-y-8">
                        <div className="flex justify-center items-center">
                          <img 
                            src={compendLogo} 
                            alt="Compend Logo"
                            className="h-8 w-auto brightness-0"
                          />
                        </div>
                        <div className="relative group">
                          <div className="max-w-sm mx-auto relative">
                            <img 
                              src={videoStill} 
                              alt={caseItem.title}
                              className="rounded-xl shadow-lg w-full cursor-pointer"
                              onClick={() => setModalImage(videoStill)}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="relative group max-w-sm mx-auto">
                        <img 
                          src={caseItem.image} 
                          alt={caseItem.title}
                          className="rounded-xl shadow-lg w-full cursor-pointer"
                          onClick={() => setModalImage(caseItem.image)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Cases;
