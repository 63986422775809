/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  BookmarkAltIcon,
  CalendarIcon,
  HomeIcon,
  FingerPrintIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  CollectionIcon,
  CogIcon,
  SupportIcon,
  PresentationChartBarIcon,
  XIcon,
} from '@heroicons/react/outline';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { ChevronDownIcon } from '@heroicons/react/solid';
import header_img from "../img/nico_img.png";
import vector_img from "../img/Vector.png";
import { socialLinks } from "../data/socialLinks";  
import { FaEnvelope } from "react-icons/fa";
import { Fade } from "react-reveal";

const cvLink = "https://drive.google.com/uc?export=download&id=1vraMfJHdC3Vl52LKYcEIPqhihF9VnlE9"
const features = [
  {
    name: 'Home',
    href: '#',
    description: 'Get a better understanding of where your traffic is coming from.',
    icon: HomeIcon,
  },
  {
    name: 'About Me',
    href: '#aboutme',
    description: 'Speak directly to your customers in a more meaningful way.',
    icon: FingerPrintIcon,
  },
  // { 
  //   name: 'Skills', 
  //   href: '#skills', 
  //   description: "Your customers' data will be safe and secure.", 
  //   icon: CogIcon},
  {
    name: 'Stats',
    href: '#stats',
    description: "Connect with third-party tools that you're already using.",
    icon: PresentationChartBarIcon,
  },
  {
    name: 'Projects',
    href: '#cases',
    description: 'Build strategic funnels that will drive your customers to convert',
    icon: CollectionIcon,
  },
]
const callsToAction = [
  { name: 'Watch Demo', href: '#', icon: PlayIcon },
  { name: 'Contact Sales', href: '#', icon: PhoneIcon },
]
const resources = [
  {
    name: 'Help Center',
    description: 'Get all of your questions answered in our forums or contact support.',
    href: '#',
    icon: SupportIcon,
  },
  {
    name: 'Guides',
    description: 'Learn how to maximize our platform to get the most out of it.',
    href: '#',
    icon: BookmarkAltIcon,
  },
  {
    name: 'Events',
    description: 'See what meet-ups and other events we might be planning near you.',
    href: '#',
    icon: CalendarIcon,
  },
  { name: 'Security', description: 'Understand how we take your privacy seriously.', href: '#', icon: CogIcon },
]
const menuButtons = [
  { anchor: "#aboutme"},
  // { anchor: "#skills"},
  { anchor: "#stats"},
  { anchor: "#cases"},
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Banner() {
  return (
    
    <div className="relative sm:h-max font-sans">

      <Popover className="relative ">
        <div className="mx-auto z-20">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1 w-48">
              <a className="w-48"href="#">
                <span className="sr-only w-48">Workflow</span>
                <p className="h-auto w-48 sm:h-auto font-bold text-2xl">
                    Nicolai Herforth
                </p>
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 transition-colors">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden md:flex space-x-10">
              <AnchorLink href="#" className="transition transform text-base font-normal text-gray-500 hover:text-gray-900 hover:scale-110 ">
                Home
              </AnchorLink>
              <AnchorLink href="#aboutme" className="transition transform text-base font-normal text-gray-500 hover:text-gray-900 hover:scale-110">
                About Me
              </AnchorLink>
              {/* <AnchorLink href="#skills" className="transition transform text-base font-normal text-gray-500 hover:text-gray-900 hover:scale-110">
                Skills
              </AnchorLink> */}
              <AnchorLink href="#stats" className="transition transform text-base font-normal text-gray-500 hover:text-gray-900 hover:scale-110">
                Stats
              </AnchorLink>
              <AnchorLink href="#cases" className="transition transform text-base font-normal text-gray-500 hover:text-gray-900 hover:scale-110">
                Projects
              </AnchorLink>

              
            </Popover.Group>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <a
                href={cvLink}
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-6 py-3 rounded-md shadow-sm text-base font-medium text-blue-600 hover:text-white bg-white-600 transition border border-blue-600 hover:bg-buttonblue hover:text-white"
              >
                Download CV
              </a>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="transition duration-300 ease-out"
          enterFrom="transform -translate-y-full opacity-0"
          enterTo="transform translate-y-0 opacity-100"
          leave="transition duration-200 ease-in"
          leaveFrom="transform translate-y-0 opacity-100"
          leaveTo="transform -translate-y-full opacity-0"
        >
          <Popover.Panel className="absolute top-0 inset-x-0 transition transform origin-top md:hidden">
            {({ close }) => (
              <div className="bg-white shadow-lg">
                <div className="max-w-full mx-auto">
                  <div className="flex items-center justify-between py-6 px-4 border-b border-gray-100">
                    <div className="flex justify-start w-48">
                      <p className="h-auto w-48 font-bold text-2xl">
                        Nicolai Herforth
                      </p>
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 transition-all">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="px-4 py-6">
                    <nav className="grid gap-y-4">
                      {features.map((item) => (
                        <AnchorLink
                          key={item.name}
                          href={item.href}
                          className="flex items-center p-3 -m-3 rounded-lg hover:bg-gray-50 transition duration-150 ease-in-out group"
                          onClick={() => close()}
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gray-50 text-blue-600 group-hover:bg-blue-50 transition duration-150">
                            <item.icon className="h-6 w-6" aria-hidden="true" />
                          </div>
                          <div className="ml-4 text-base font-medium text-gray-900">
                            {item.name}
                          </div>
                        </AnchorLink>
                      ))}
                    </nav>
                    <div className="mt-6 pt-6 border-t border-gray-200">
                      <a
                        href={cvLink}
                        className="w-full flex items-center justify-center px-4 py-3 rounded-md text-base font-medium text-blue-600 bg-white border border-blue-600 hover:bg-buttonblue hover:text-white transition-all duration-150"
                        onClick={() => close()}
                      >
                        Download CV
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>


      <main className="lg:relative h-full z-10 flex flex-col">
        <div className="mx-auto w-full pt-16 pb-10 sm:pb-20 text-left">
          <div className="lg:w-4/5 xl:pr-16">
            {/* <div className="flex items-center bg-blue-100 w-max h-7  px-3 rounded-lg mb-5 lg:m-0 lg:mb-10 sm:mb-10">
              <img alt='' src={vector_img} className="w-5 h-5 mr-2"/>
              <p className="font-semibold text-sm">PRODUCT INNOVATOR</p>
            </div> */}
            <h1 className="text-4xl font-medium text-gray-900 max-w-lg md:max-w-xl sm:text-5xl md:text-5xl lg:text-6xl sm:mb-10">
              <span className="block xl:inline tracking-wide">
                <div className="mb-2 sm:mb-3">
                  Tackling <span className="font-black bg-gradient-to-r from-blue-900 via-blue-700 to-blue-800 bg-clip-text text-transparent">Hard</span> Problems.
                </div>
                <div>
                  Delivering <span className="font-black bg-gradient-to-r from-blue-900 via-blue-700 to-blue-800 bg-clip-text text-transparent">Real</span> Impact.
                </div>
              </span>
            </h1>
            <p className="mt-3 max-w-full text-lg text-gray-800 md:mt-5 md:max-w-md lg:mt-10 lg:mb-10 lg:text-xl">
              I lead projects and build AI systems that turn complex data into tools businesses can really use.
            </p>
            <div className="mt-10 sm:flex justify-start space-y-4 sm:space-y-0">
              <div className="rounded-md mr-2">
                <AnchorLink
                  href="#aboutme"
                  className="flex px-3 py-2 w-max font-medium items-center justify-center border border-transparent rounded-lg text-white bg-buttonblue transition hover:bg-blue-700 md:py-3 md:text-lg md:px-6 sm:px-5 sm:py-3"
                >
                  See More
                </AnchorLink>
              </div>
              <div className="md:hidden rounded-md">
                <a
                  href={cvLink}
                  className="flex px-3 py-2 w-max font-medium items-center justify-center border border-blue-600 rounded-lg text-blue-600 bg-white transition hover:bg-buttonblue hover:text-white md:py-3 md:text-lg md:px-6 sm:px-5 sm:py-3"
                >
                  Download CV
                </a>
              </div>
            </div>

            <div className="flex justify-start mt-20 flex-col lg:flex-row lg:justify-start">
              <p className="mr-6 text-base">Get me on</p>
              <div className="flex  mt-5 lg:mt-0">
                {socialLinks.map(item => (
                  <a
                    key={item.id}
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                    className="mr-6 text-2xl text-gray-400 lg:text-2xl lg:mr-6"
                    >
                      {item.icon}
                    </a>
                ))}
                <a href="mailto:nicolaiherforth@gmail.com" className="text-2xl text-gray-400 lg:text-2xl">
                    <FaEnvelope />
                </a>
              </div>
            </div>

        </div>
          
        </div>

          <div className="flex items-center -z-10 justify-center relative w-full h-72 sm:h-96 sm:block sm:absolute sm:inset-y-0 sm:right-0 sm:w-1/3 sm:h-full">
            <img
              className="absolute h-full max-w-sm sm:bottom-0  sm:w-auto sm:h-2/5 md:h-3/6 lg:block lg:bottom-20 lg:h-3/5 -z-10"
              src={header_img}
              alt=""
            />
            <div className="absolute bg-blue-100 h-52 bottom-0 w-52 rounded-t-full -z-20  sm:h-60 sm:w-60 sm:bottom-0 md:block md:h-72 md:w-72 lg:w-80 lg:h-80 lg:bottom-20"></div>
          </div>

      </main>

    </div>
  )
}
